<template>
	<div>
		<CCard class="p-4">
		<CRow>
			
			<CCol lg="6">
					<h5>Equipments({{dataList.total}})</h5>
			</CCol>
	     		 <CCol lg="6">
					   <CButton size="sm" style="float: right;" :class="'float-lg-right block '+showCollapse ? 'collapsed' : null"
			                 :aria-expanded="showCollapse ? 'true' : 'false'"
			                 aria-controls="collapse1"
			                 @click="showCollapse = !showCollapse"
			                 color="info"><i class="fa fa-search"></i>Filter<i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
			        <CButton 
						v-if="config.getPermission('equipment').create"
						size="sm" style="margin-right: 10px" 
						color="success" href="#/main/create_equipment" target="_blank"
						class="float-lg-right"><i class="fa fa-plus"></i>Add Equipment
					</CButton>
		      
			    </CCol>
		</CRow>
		<CRow> 
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
				        <CCard class="p-4">
				          <Search @depot-search-query="search"/>
				        </CCard>
				      </CCollapse> 
				</CCol>
		</CRow> 
		<hr>

		<CRow>			
	      <!-- <CCol lg="12" class="text-center" v-if="isLoading"> 
				  <div class="spinner-border m-5" role="status">
						<span class="sr-only">Loading...</span>
					</div>
			  </CCol> -->
		      <CCol lg="12">  
				<div class="table-responsive-sm table-responsive-md table-responsive-lg table-responsive-xl">
	      		<CDataTable
					:loading="isLoading"
			      	:items="dataList.data"
			      	:fields="fields"
					hover
					outlined
					
					
			    >  
				<template #status="{item}">
								 <td style="padding: 5px; width: 100px; text-transform:uppercase;">
            					<CBadge 
								 style="margin-top:10px"		
									:color="getBadge(item.status)">{{item.status}}</CBadge>
         						 </td>
					      	</template>

			    	<template #action="{item}">
				        <td style="padding: 5px; width: 100px">
				        	<CDropdown 
				                toggler-text="Select"  
								size="sm"
				                color="info"
				            >
				            	<CDropdownItem v-if="config.getPermission('equipment').view" @click="viewModal(item)"><i class="fa fa-eye" > View </i></CDropdownItem>  
				            	<CDropdownItem v-if="config.getPermission('equipment').update" @click="updateModal(item)"><i class="fa fa-pencil-square-o" > Update </i></CDropdownItem>  
								<CDropdownItem v-if="config.getPermission('equipment').delete" @click="deleteItem(item)"><i class="fa fa-trash" > Delete </i></CDropdownItem>   
								<CDropdownItem v-if="config.getPermission('equipment_transfer').update" @click="opentransferModal(item)"><i class="fa fa-paper-plane"> Transfer Equipment </i></CDropdownItem>
								<CDropdownItem @click="openhistoryModal(item)"> <i class="fa fa-list-alt"> Transfer History </i></CDropdownItem>  
				            
								
				            </CDropdown>
				        </td>
			      	</template> 
			    </CDataTable> 
			    <CPagination
		          	:active-page.sync="currentPage"
		          	:pages="Math.ceil(dataList.total / 20)"
		          	:activePage="currentPage"
		          	@update:activePage="updatePage"
		        /> 
			</div>
	      	</CCol>
	    </CRow> 
		  <HistoryModal :details="selectedRow" @showModal="historyModal = $event; reloadData();" :showModal="historyModal" />
		   <TransferModal :details="selectedRow" @showModal="transferModal = $event; reloadData();" :showModal="transferModal" />
		</CCard>
	</div>
</template>
<script> 
import config from '../../config.js';
import axios from '../../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
import moment from 'moment';
import HistoryModal from './equip_history_modal/index';
import TransferModal from './transfer_equipment_modal/index';
import '../../style.css';


export default {
	mounted(){  
		// this.getVendor();
		// this.getDepot();
		this.getData();  
	},
	data(){
		return{
			isLoading: false,
			config,
			historyModal: false,
			transferModal: false,
			// dispatchModal: false,
			// deliverModal: false,
			// completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],  




			depotName: "", 
			fields: [
				{
					key: 'classification', 
					label: 'Classification'
				},
				{
					key: 'equipment_prep_no', 
					label: 'Equipment Prep No.'
					
				},
				{
					key: 'asset_no', 
					label: 'Asset No.'
				},
				
				{
					key: 'description', 
					label: 'Description'
				}, 
				{
					key: 'model', 
					label: 'Model'
				},
				{
					key: 'type', 
					label: 'Type'
				},
				// {
				// 	key: 'acquired_date', 
				// 	label: 'Acquired Date'
				// },
				{
					key: 'origin_id_label', 
					label: 'Origin'
				},
				// {
				// 	key: 'acquisition_date', 
				// 	label: 'Acquisition Date'
				// },
				{
					key: 'current_location_id_label', 
					label: 'Current Location',
				}, 
				{
					key: 'depot_name', 
					label: 'Depot'
				},
				{
					key: 'status', 
					label: 'Status'
				},
				{
					key: 'action', 
				
					label: 'Action'
				}
			],
			currentPage: 1,  


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				classification: "",
				description:"",
				origin_id: "",
				current_location_id:"",
				equipment_prep_no:"",
				model:"",
				status:"",
				asset_no: "",
				type:"",
			},
			vendorList: {
				data: []
			},
			depotList: {
				data:[]
			}
		}
	},
	name: 'Tables',
	components: {Search, Datepicker, vSelect, HistoryModal, TransferModal},
	methods: {
		 getBadge (status) {
    	return status === 'operational' ? 'success'
       		 : status === 'breakdown' ? 'dark'
         	 : status === 'for scrap' ? 'warning'
			 : status === 'standby' ? 'secondary'
			 : status === 'for erection' ? 'primary'
         	 : status === 'for inspection' ? 'info'
			 : status === 'dismantle' ? 'light'
			 : status === 'for rehab' ? 'success'
			 : status === 'pull-out' ? 'warning'
             : status === 'reserve' ? 'danger' : 'primary'
    },
		reloadData(){
			this.getData();  
		},
		updatePage(data){
			this.currentPage = data;
			this.getData();
		},   

	    toggleModal(){
	    	this.formModal = !this.formModal;
	    }, 

	    search(event){
	    	this.filter = event; 
	    	this.getData();  
	    }, 
		openhistoryModal(item){
	    	this.historyModal = true;
	    	this.selectedRow = item;
	    },
		opentransferModal(item){
	    	this.transferModal = true;
	    	this.selectedRow = item;
	    },
	    getData(){  
			this.isLoading=true;
	    	var current_location_id = this.filter.current_location_id;
	    	if(current_location_id == "all" || current_location_id == null){
	    		current_location_id = ""
	    	}
	    	var origin_id = this.filter.origin_id;
	    	if(origin_id == "All" || origin_id == null){
	    		origin_id = ""
	    	} 

	    	axios.get(config.api_path+'/equipment?description='+this.filter.description+'&equipment_prep_no='+this.filter.equipment_prep_no+'&classification='+this.filter.classification+'&current_location_id='+current_location_id+'&origin_id='+origin_id+'&status='+this.filter.status+'&asset_no='+this.filter.asset_no+'&type='+this.filter.type+'&model='+this.filter.model+'&page='+this.currentPage+'&limit=20')
	    	.then(response => {
	    		this.dataList = response.data; 
	    		this.dataList.data = this.dataList.data.map((value, index)=>{ 
	    			value.asset_no = value.asset_no && value.asset_no != "null" ? value.asset_no : "N/A";
	    			value.transfer_date = value.transfer_date;
	    			// value.transfer_date = value.transfer_date ? moment.unix(value.transfer_date).format('MM/DD/YYYY') : " ";
	    			value.acquisition_date = value.acquisition_date ? moment.unix(value.acquisition_date).format('MM/DD/YYYY') : " ";
					value.acquired_date = value.acquired_date ? moment.unix(value.acquired_date).format('MM/DD/YYYY') : "";
					value.equipment_prep_no = value.equipment_prep_no ?? '';
					value.model = value.model ?? '';
					value.depot_name = value?.depot?.setting_name ?? '-';
					value.description = value?.description ? this.$options.filters.truncate(value.description):'-';

 					if(value.origin_id){
 						value.origin_id_label = value.origin.customer_name
 					}
 					else{
 						value.origin_id_label = "N/A";
 					}

 					value.current_location_id_label = "N/A";
 					if(value.current_location_id){
 						value.current_location_id_label = value.current_location.customer_name
 					} 

 					value.equipment_history = value.equipment_history.map((val, i)=>{
 						if(val.current_location){
 							val.current_location_id_label = val.current_location.customer_name
 						}
 						if(val.origin){
 							val.origin_id_label = val.origin.customer_name
 						}
 						if(val.user){
 							val.users_id_label = val.user.firstname+" "+val.user.lastname;
 						}
 						val.transfer_date = val.transfer_date ? moment.unix(val.transfer_date).format('MMMM DD, YYYY') : "N/A";
 						return val;
 					})

	    			return value;
	    		}); 
	    	}).catch(err => {
				console.error(err)
				this.isLoading = false;
			})
			.finally(fin => {
				console.log(fin)
				this.isLoading = false;	
			})
	    },

	    
	    updateModal(item){ 
	    	this.$router.push('/main/update_equipment/'+item.id) 
	    },

	    viewModal(item){ 
	    	this.$router.push('/main/view_equipment/'+item.id) 
	    },

	    deleteItem(item){
	    	Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to delete this equipment?', 
				showCancelButton: true,
				confirmButtonText: `Yes, delete`, 
				confirmButtonColor: 'red',
				reverseButtons:true,
			}).then((result) => { 
				if (result.isConfirmed) {
					axios.delete(config.api_path+'/equipment/'+item.id)
			    	.then(response => {
			    		this.getData(); 
			    		Swal.fire({
							title: 'Success!',
							text: "Equipment successfully deleted",
							icon: 'success', 
						})
			    	}) 
				}
			})  
	    },

 

 	}
}
</script>
